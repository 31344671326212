import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import { CaseStudiesFilter } from '../components/CaseStudies/CaseStudiesFilter';
import ServiceFilters from '../components/CaseStudies/ServiceFilters';
import { TextHeader } from '../acf/TextHeader/TextHeader.jsx';
import { FormBlock } from '../acf/FormBlock/FormBlock.jsx';
import { decodeEntities } from '../utils/htmlParse';
import './casestudies.scss';

const getAllViewableServiceElements = (caseStudies, serviceNodes) => {
  const allViewableServiceIds = caseStudies.reduce((acc, {node: caseStudy}) => {
    const { case_study_service: services } = caseStudy;
    const distinctServiceIds = new Set(acc.concat(services));
    return Array.from(distinctServiceIds);
  }, []);
  const allViewableServiceElements = serviceNodes.nodes.filter(service => {
    return allViewableServiceIds.indexOf(service.wordpress_id) !== -1;
  });
  return allViewableServiceElements;
}

export default class CaseStudiesPage extends React.Component {
  render() {
    const { data, pageContext, location } = this.props;
    const { site, allWordpressWpCaseStudy, caseStudiesPageComponents, allWordpressWpCaseStudyService } = data;
    if (!allWordpressWpCaseStudy || !caseStudiesPageComponents) return null;
    const {
      childWordPressAcfTextHeader = null,
      childWordPressAcfFormBlock = null,
    } = caseStudiesPageComponents;
    const {
      title: siteTitle,
      caseStudiesSlug
    } = site.siteMetadata;
    const { edges: caseStudies } = allWordpressWpCaseStudy;
    const allViewableServiceElements = getAllViewableServiceElements(caseStudies, allWordpressWpCaseStudyService);
    const mainServices = allViewableServiceElements.filter(el => el.wordpress_parent === 0);
    const subServices = allViewableServiceElements.filter(el => el.wordpress_parent !== 0);

    return (
      <Layout location={location} className="case-studies-template">
        <SEO title={`Pivotal Projects | ${decodeEntities(siteTitle)}`} />
        {childWordPressAcfTextHeader && (
          <TextHeader
            smallHeading={childWordPressAcfTextHeader.smallHeading}
            heading={childWordPressAcfTextHeader.heading}
          />
        )}
        {/* <ServiceFilters
          caseStudiesSlug={caseStudiesSlug}
          mainServices={mainServices}
          subServices={subServices}
          pathPrefix={`/${caseStudiesSlug}/`}
        /> */}
        <CaseStudiesFilter
          location={location}
        />
        {childWordPressAcfFormBlock && (
          <FormBlock
            smallHeading={childWordPressAcfFormBlock.smallHeading}
            heading={childWordPressAcfFormBlock.heading}
            form={childWordPressAcfFormBlock.form}
            location={location}
          />
        )}
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query CaseStudiesQuery($limit: Int!, $skip: Int!) {
    site {
      siteMetadata {
        title
        caseStudiesSlug
      }
    }
    caseStudiesPageComponents: wordpressPage(slug: { eq: "pivotal-projects" }) {
      childWordPressAcfTextHeader {
        smallHeading
        heading
        subHeading
      }
      childWordPressAcfFormBlock {
        smallHeading
        heading
        form
      }
    }
    allWordpressWpCaseStudy(
      sort: { fields: date, order: DESC }
      filter: { slug: { ne: "gatsby-build-case-study" } }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          featuredImage: featured_media {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1200, quality: 90) {
                  ... GatsbyImageSharpFluid
                }
              }
            }
          }
          title
          content
          excerpt
          date(formatString: "MMMM DD, YYYY")
          slug
          case_study_service
        }
      }
    }
    allWordpressWpCaseStudyService {
      nodes {
        name
        slug
        wordpress_id
        wordpress_parent
      }
    }
  }
`
